$(document).ready(function() {

    var $body = $('body');
    var noJsClass = 'no-js';

    removeClass(noJsClass, $body);

    // Smooth scroll
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // Slide open trigger, close on nav link click
    $('#navToggle').click(function(event) {
        event.preventDefault();

        toggleNavigation();
    });

    // Local nav trigger
    $('#localNavToggle').click(function(event) {
        event.preventDefault();

        var openClass = 'open';
        var $localNav = $('.nav--local');

        if ($localNav.hasClass(openClass)) {
            removeClass(openClass, $localNav);
        } else {
            addClass(openClass, $localNav);
        }
    });

    // Close menu on select
    $('.nav--local li a').on('click', function() {
        var openClass = 'open';
        var $localNav = $('.nav--local');

        removeClass(openClass, $localNav);
    });

    // Open/close modal
    $('.modal-toggle, .close, .modal--video, .person-toggle').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();

        var openClass = 'modal--open';
        var closeClass = 'modal--closed';
        var overflowClass = 'no-scroll';

        var $body = $('body');
        var $element = $(this).parents(".has-modal").children('.modal');
        toggleModal(closeClass, openClass, $element);
        toggleBodyScroll(overflowClass, $body);
    });

	$body.on('click', function (event) {

		if ( $('.modal--open').length && $(event.target).is('.modal--open') ) {
			toggleModal('modal--closed', 'modal--open', $('.modal--open'));
			toggleBodyScroll('no-scroll', $('body'));
		}
	});

    // Pause video playback on modal close
    $('.modal--video .close, .modal--video').on('click', function(event) {
        $(".modal iframe").attr("src", $(".modal iframe").attr("src"));
    });

    // Product component
    $('.component').click(function() {

        var $components = $('.component'),
            componentPoint = $(this).attr('id'),
            slideNumber = 'slide--' + componentPoint,
            activeClass = 'active',
            hideClass = 'hide';

        if ($components.hasClass(activeClass)) {
            removeClass(activeClass, $components);
        }

        addClass(activeClass, this);
        removeClass(hideClass, $('.' + slideNumber));
        addClass(hideClass, $('.' + slideNumber).siblings());
    });


    //search toggle
    $('.search-toggle a').on('click', function(event) {
        event.preventDefault();

        var className = 'hide-form';
        var $searchForm = $('.search-form');

        if ($searchForm.hasClass(className)) {
            removeClass(className, $searchForm);
        } else {
            addClass(className, $searchForm);
        }
    });


    // float labels
    var form = '#inquiryForm-form';

    $(form + ' input,' + form + ' textarea').on('blur', function() {

        var $element = $(this).closest('.field');
        var activeClass = 'active';
        var fillClass = 'filled';

        removeClass(activeClass, $element);

        if ($(this).val() !== '') {
            addClass(fillClass, $element);

        } else {
            removeClass(fillClass, $element);
        }

    }).on('focus', function() {
        var $element = $(this).closest('.field');

        addClass('active', $element);
    });

    //trigger form reset - this is a firefox thing
    $('form').each(function() {
        $(this).trigger("reset");
    });


    // functions

    //navigation toggle
    function toggleNavigation() {

        var openClass = 'open';
        var scrollClass = 'noscroll';

        var $mobileNav = $('.navigation');
        var $body = $('body');
        var $topbar = $('.topbar');

        if ($mobileNav.hasClass('open')) {
            removeClass(openClass, $mobileNav);
            removeClass(openClass, $topbar);
            removeClass(scrollClass, $body);
        } else {
            addClass(openClass, $mobileNav);
            addClass(openClass, $topbar);
            addClass(scrollClass, $body);
        }
    }

    // Modal toggle
    function toggleModal(closeClass, openClass, $element) {
        if ($element.hasClass(closeClass)) {
            addClass(openClass, $element);
            removeClass(closeClass, $element);
        } else {
            removeClass(openClass, $element);
            addClass(closeClass, $element);
        }
    }

    // Body scroll toggle
    function toggleBodyScroll(overflowClass, $body) {
        if ($body.hasClass(overflowClass)) {
            removeClass(overflowClass, $body);
        } else {
            addClass(overflowClass, $body);
        }
    }

    function addClass(className, element) {
        $(element).addClass(className);
    }

    function removeClass(className, element) {
        $(element).removeClass(className);
    }

    // Calculator
    var SSICalculator = {

        // Create our number formatter.
        formatter: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            // the default value for minimumFractionDigits depends on the currency
            // and is usually already 2
        }),

        // Preset subgroup values
        presets: {
            allcolorectal:
                {
                    currentRate: 15.0,
                    numberOfProcedures: 300,
                    costOfSsi: 19000,
                    ssiReduction: 61
                },
            ibd:
                {
                    currentRate: 18.9,
                    numberOfProcedures: 47,
                    costOfSsi: 19000,
                    ssiReduction: 61
                },
            obese:
                {
                    currentRate: 18.3,
                    numberOfProcedures: 73,
                    costOfSsi: 19000,
                    ssiReduction: 61
                },
            diverticulitis:
                {
                    currentRate: 13.1,
                    numberOfProcedures: 69,
                    costOfSsi: 19000,
                    ssiReduction: 61
                },
            diabetes:
                {
                    currentRate: 18.9,
                    numberOfProcedures: 51,
                    costOfSsi: 19000.00,
                    ssiReduction: 61
                },
            stoma:
                {
                    currentRate: 28.4,
                    numberOfProcedures: 73,
                    costOfSsi: 19000,
                    ssiReduction: 61
                },
            emergency:
                {
                    currentRate: 25.6,
                    numberOfProcedures: 71,
                    costOfSsi: 19000,
                    ssiReduction: 61
                }

        },

        $subgroupSelect: null,
        $calculatorInput: null,
        calculatedTotal: null,
        currentValue: null,

        init: function() {

            this.$subgroupSelect = $('#subgroup');
            this.$calculatorInput = $('#calc input');

            this.$subgroupSelect.on('change', function() {
                var selectedSubgroup = this.value.toLowerCase();

                if (selectedSubgroup !== 'custom') {
                    SSICalculator.populateCalculator(selectedSubgroup);
                } else {
                    SSICalculator.updateValue($('input'), '');
                    SSICalculator.updateAttribute($('input'), 'placeholder', '');
                    SSICalculator.updateValue($('#total'), '$0.00');
                }
            });

            this.$calculatorInput.on('focus', function() {
                SSICalculator.currentValue = this.value;
                var $input = $(this);

                $input.val('');
                SSICalculator.updateAttribute($input, 'placeholder', SSICalculator.currentValue);
            });

            this.$calculatorInput.on('blur', function() {
                var $input = $(this);

                if ($input.val() == '') {
                    // if set to '' when leaving focus restore previous value
                    $input.val(SSICalculator.currentValue);
                } else {
                    // switch to custom setting in dropdown
                    $('#customOption').attr('selected', 'selected');
                }
            });

            this.$calculatorInput.on('keyup', function() {
                $('#customOption').attr('selected', 'selected');
                SSICalculator.calculateTotalCost();
                SSICalculator.updateValue($('#total'), SSICalculator.formatter.format(SSICalculator.calculatedTotal));
            });

            // Set our default values
            SSICalculator.populateCalculator('allcolorectal');
        },

        populateCalculator: function(selectedSubgroup) {

            var $currentRateInput = $("#currentRate"),
                $numberOfProceduresInput = $("#numberOfProcedures"),
                $costOfSsiInput = $("#costOfSsi"),
                $ssiReductionInput = $("#ssiReduction");

            this.updateValue($currentRateInput, this.presets[selectedSubgroup].currentRate);
            this.updateValue($numberOfProceduresInput, this.presets[selectedSubgroup].numberOfProcedures);
            this.updateValue($costOfSsiInput, this.presets[selectedSubgroup].costOfSsi);
            this.updateValue($ssiReductionInput, this.presets[selectedSubgroup].ssiReduction);

            this.calculateTotalCost();
            this.updateValue($('#total'), this.formatter.format(this.calculatedTotal));
        },

        // Calculate Total
        calculateTotalCost: function() {

            var $currentRateInput = $("#currentRate").val(),
                $numberOfProceduresInput = $("#numberOfProcedures").val(),
                $costOfSsiInput = $("#costOfSsi").val(),
                $ssiReductionInput = $("#ssiReduction").val();

            var numberOfPatientsWithSSI = ($currentRateInput / 100) * $numberOfProceduresInput;
            var totalCostOfSSICareManagement = numberOfPatientsWithSSI * $costOfSsiInput;
            var percentReductionInSSI = ($ssiReductionInput / 100);

            var calculatedTotal = totalCostOfSSICareManagement * percentReductionInSSI;

            this.calculatedTotal = Math.round(calculatedTotal);
        },

        updateValue: function($element, value) {
            $element.val(value);
        },

        updateAttribute: function($element, attribute, value) {
            $element.attr(attribute, value);
        }
    };

    SSICalculator.init();
});








